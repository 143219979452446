import React from 'react'
import logo from '../public/svg/logo.svg'

export default function App() {
  return (
    <div className="flex flex-col justify-center items-center h-screen bg-slate-500">
        <img src={logo} className="w-64" alt="logo" />
        <div className="mt-10 text-center">
            <h1 className="text-4xl text-black font-bold">Your personal AI financial adviser</h1>
            <p className="text-xl mt-8 text-black/70 font-bold">Coming soon...</p>
        </div>
    </div>
  )
}
